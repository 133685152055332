<template>
  <div v-if="m.total > 0">
    <div class="mx-2 mb-2 mt-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Показано с {{ m.from }} по {{ m.to }} из {{ m.total }} записей</span>
        </b-col>
        <b-col
          v-if="m.pages > 1"
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            :value="m.page"
            :total-rows="m.total"
            :per-page="m.page_size"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @page-click="clickHandler"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { BCol, BPagination, BRow } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
  },
  props: {
    meta: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      m: {
        page: 1,
        pages: 1,
        page_size: 25,
        from: 0,
        to: 0,
        total: 0,
      },
    }
  },
  methods: {
    applyChanges() {
      this.m = Object.assign(this.m, {
        page: this.meta.current_page,
        pages: this.meta.last_page,
        page_size: this.meta.per_page,
        total: this.meta.total,
        to: this.meta.to,
        from: this.meta.from,
      })
    },
    clickHandler(_, num) {
      this.$emit('page', num)
    },
  },
  mounted() {
    this.applyChanges()
  },
  watch: {
    meta: {
      deep: true,
      handler() {
        this.applyChanges()
      },
    },
  },
}
</script>
