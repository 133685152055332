<template>
  <div class="print-land">
    <div
      v-if="!hidePageSize || !hideCreateButton"
      class="m-2 no-print"
    >
      <b-row>
        <b-col
          v-if="!hidePageSize"
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-table-pagination-options
            v-model="meta.page_size"
            @input="pageSizeInputHandler"
          />
        </b-col>
        <slot
          v-if="!hideCreateButton"
          name="create-button"
        >
          <b-col
            cols="12"
            md="8"
            class="d-lg-flex flex-row-reverse"
          >
            <b-button
              variant="primary"
              @click="$emit('create')"
            >
              Добавить
            </b-button>
          </b-col>
        </slot>
      </b-row>
    </div>
    <slot name="before-table" />
    <div class="table-wrapper">
      <b-table
        ref="data-table"
        :items="fetchData"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-icon-left
        show-empty
        empty-text="Пусто"
        class="position-relative"
        :small="tableSm"
        :tbody-tr-class="tbodyTrClass"
      >
        <template
          v-for="(_, name) in $scopedSlots"
          :slot="name"
          slot-scope="slotData"
        >
          <slot
            :name="name"
            v-bind="slotData"
          />
        </template>
      </b-table>
    </div>
    <slot name="after-table"></slot>
    <slot name="pagination">
      <v-table-pagination
        class="no-print"
        :meta="meta"
        @page="pageHandler"
      />
    </slot>
  </div>
</template>
<script>
import {
  BCol, BRow, BTable, BButton,
} from 'bootstrap-vue'
import VTablePaginationOptions from './VTablePaginationOptions.vue'
import VTablePagination from './VTablePagination.vue'

export default {
  components: {
    BCol,
    BRow,
    BTable,
    BButton,
    VTablePaginationOptions,
    VTablePagination,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    primaryKey: {
      type: String,
      default: 'id',
    },
    filter: {
      type: Object,
      default() {
        return {}
      },
    },
    sort: {
      type: String,
      default: '-id',
    },
    tableSm: {
      type: Boolean,
    },
    hideCreateButton: {
      type: Boolean,
      default: false,
    },
    hidePageSize: {
      type: Boolean,
      default: false,
    },
    tbodyTrClass: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      items: [],
      meta: {
        page_size: 25,
        page: 1,
        pages: 1,
        from: 0,
        to: 0,
        total: 0,
      },
      sortBy: 'id',
      sortDesc: true,
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.pageHandler(1)
        this.refresh()
      },
    },
    path() {
      this.pageHandler(1)
      this.refresh()
    },
    $route(to) {
      let needRefresh = false
      const page = Object.hasOwn(to.query, 'page') ? to.query.page : this.meta.page
      const pageSize = Object.hasOwn(to.query, 'page_size') ? to.query.page_size : this.meta.page_size
      if (pageSize !== this.meta.page_size) {
        needRefresh = true
        this.meta.page_size = pageSize
      }
      if (page !== this.meta.page) {
        needRefresh = true
        this.meta.page = page
      }
      if (needRefresh) {
        this.refresh()
      }
    },
  },
  mounted() {
    this.parseSortProp()
    if (Object.hasOwn(this.$route.query, 'page_size')) {
      this.meta.page_size = this.$route.query.page_size
    }
    if (Object.hasOwn(this.$route.query, 'page')) {
      this.meta.page = this.$route.query.page
    }
    const pageSize = window.localStorage.getItem(`table_${this.path}`)
    if (pageSize !== null) {
      this.meta.page_size = pageSize
    }
  },
  methods: {
    parseSortProp() {
      if (this.sort) {
        this.sortDesc = this.sort[0] === '-'
        this.sortBy = this.sort[0] === '-' ? this.sort.slice(1) : this.sort
      }
    },
    filterObj() {
      const result = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key === 'search') {
          result[key] = value
        } else {
          result[`filter[${key}]`] = value
        }
      }
      return result
    },
    fetchData() {
      const sort = { sort: (this.sortDesc ? '-' : '') + this.sortBy }
      const pagination = {
        page: this.meta.page,
        page_size: this.meta.page_size,
      }
      const params = { ...this.filterObj(), ...sort, ...pagination }
      return this.$axios.get(this.path, { params })
        .then(response => {
          this.items = response.data.data
          this.meta = Object.assign(this.meta, response.data.meta)
          return response.data.data
        })
        .catch(() => [])
    },
    refresh() {
      this.$refs['data-table'].refresh()
    },
    pageHandler(page) {
      this.meta.page = page
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.meta.page,
          page_size: this.meta.page_size,
        },
      }).catch(() => {})
    },
    pageSizeInputHandler(value) {
      this.meta.page_size = value
      window.localStorage.setItem(`table_${this.path}`, value)
      this.pageHandler(1)
    },
  },
}
</script>
<style>
 .table-wrapper{
   max-width: 100%;
   overflow-x: auto;
 }
</style>
