<template>
  <div>
    <label class="mb-0">Отображать</label>
    <v-select
      :value="value"
      :options="optionsArr"
      :clearable="false"
      class="per-page-selector d-inline-block mx-50"
      @input="(value) => $emit('input', value)"
    />
    <label>строк</label>
  </div>
</template>
<script>
import VSelect from 'vue-select'
export default {
  components: {
    VSelect
  },
  props: ['value', 'options'],
  computed: {
    optionsArr () {
      return this.options ? this.options : [25, 50, 75, 100]
    }
  }
}
</script>