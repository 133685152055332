<template>
  <span>{{ data.index + from }}</span>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {
          index: 0,
        }
      },
    },
  },
  computed: {
    from() {
      return this.$parent.$parent.$parent.$parent.$data.meta.from
    },
  },
}
</script>
